/** <==== RESPONSE DATA TYPES =============================================> */
export type IDialogMessages = IDialog[]

export interface IChatMessages {
    messages: IMessage[]
    profile: IFriend
}

export interface ISentMessage {
    message: IMessage
    profile: IFriend
}

export interface IDeletedMessage {
    result: boolean
}

/** <==== REQUEST DATA TYPES ==============================================> */
export interface IPagination {
    uuid?: string
    limit: number
    offset: number
    // For disable query caching
    timeStamp?: number
}

export interface ITextMessage {
    uuid: string
    message: string
    reply_to?: number
}

export interface IGeoMessage {
    uuid: string
    lat: number
    lon: number
    reply_to?: number
}

export interface IPhotoMessage extends FormData {
    append(name: PhotoMessageFields, value: Blob):void
}

export interface IVoiceMessage extends FormData {
    append(name: VoiceMessageFields, value: Blob):void
}

export interface IEditMessage {
    messageId: number
    message: string
    reply_to?: number
}

export interface ISelectedMessages {
    ids: number[]
}

/** <==== SUB DATA TYPES ==================================================> */
export interface IDialog {
    id: number
    last_message: ILastMessage
    profile: IFriend
    unread_count: number
}

export interface ILastMessage {
    attachment: IAttachment | null
    created_at: string
    icon: string
    id: number
    self: boolean
    text: string | null
    type: TypeOfMessage
}

export interface IMessage {
    attachment: IAttachment | null
    created_at: string
    id: number
    read: boolean
    reply: IParentMessage | null
    self: boolean
    sender_name: string
    text: string | null
    type: TypeOfMessage
}

export interface IParentMessage {
    attachment: IAttachment | null
    id: number
    self: boolean
    sender_name: string
    text: string | null
    type: TypeOfMessage
}

export interface IAttachment {
    photo?: string[]
    voice?: string
    location?: ILocation
}

export interface IFriend {
    age: number
    avatar: string
    id: number
    name: string
    online: boolean
    uuid: string
}

export interface ILocation {
    lat: number
    lon: number
}

export type PhotoMessageFields = 'id' | 'file' | 'message' | 'reply_to'

export type VoiceMessageFields = 'id' | 'file' | 'reply_to'

export type TypeOfMessage = 'message' | 'location' | 'photo' | 'voice' | 'automatic'

export enum MessageTypes {
    Text = 'message',
    Geo = 'location',
    Voice = 'voice',
    Photo = 'photo',
    Automatic = 'automatic',
}


